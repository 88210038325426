<template>
  <div :class="{ 'has-logo': showLogo }">
    <logo v-if="showLogo" :collapse="isCollapse" />
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        :default-active="activeMenu"
        :collapse="isCollapse"
        :background-color="variables.menuBg"
        :text-color="variables.menuText"
        :unique-opened="false"
        active-text-color="#ffffff"
        :collapse-transition="false"
        mode="vertical"
      >
        <sidebar-item
          v-for="route in permission_routes"
          :key="route.path"
          :item="route"
          :base-path="route.path"
        />
        <!-- <sidebar-item v-for="route in constantRoutes" :key="route.path" :item="route" :base-path="route.path" /> -->
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Logo from './Logo'
import SidebarItem from './SidebarItem'
import variables from '@/styles/variables.scss'
export default {
  name: 'SideBar',
  components: { SidebarItem, Logo },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['permission_routes', 'sidebar']),
    activeMenu() {
      const menu = this.getActiveMenu(this.permission_routes)
      return menu
    },
    showLogo() {
      return this.$store.state.settings.sidebarLogo
    },
    variables() {
      return variables
    },
    isCollapse() {
      return !this.sidebar.opened
    }
  },
  methods: {
    /**
     * currentPath 当前路由 path
     * routesData 路由数据源对象
    */
    findMatchMenu(currentPath, routesData, prifix = '') { // 获取匹配菜单
      let result = prifix
      for (let i = 0; i < routesData.length; i++) {
        const path = prifix + (routesData[i].path.startsWith('/') ? routesData[i].path : '/' + routesData[i].path)
        if (currentPath === path) {
          const meta = routesData[i].meta
          if (meta && meta.activeMenu) {
            result = meta.activeMenu
            break
          }
          result = path
          break
        }
        if (currentPath.startsWith(path) && routesData[i].children) {
          result = this.findMatchMenu(currentPath, routesData[i].children, path)
          break
        }
      }
      return result
    },
    getActiveMenu(permissionRoutes) {
      const { path } = this.$route
      return this.findMatchMenu(path, permissionRoutes) || window.location.pathname || '/'
    }
  }
}
</script>
