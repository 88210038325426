import Vue from 'vue'
import Cookies from 'js-cookie'
import 'normalize.css/normalize.css' // a modern alternative to CSS resets
import Element from 'element-ui'
import './styles/element-variables.scss'
// import enLang from 'element-ui/lib/locale/lang/en'// 如果使用中文语言包请默认支持，无需额外引入，请删除该依赖
// import { removeToken, removeRefreshToken } from '@/utils/auth'
import '@/styles/index.scss' // global css
import App from './App.vue'
import store from './store'
import router from './router'

import './icons' // icon
import './router/permission' // permission control
import './utils/error-log' // error log
Vue.use(Element, {
  size: Cookies.get('size') || 'medium' // set element-ui default size
  // locale: enLang // 如果使用中文，无需设置，请删除
})

window._AMapSecurityConfig = {
  key: 'aae86f7f4a9c149094344f58bcde4618',
  securityJsCode: '5106f316aa8adb0fb8db03e4b2994155'
}

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')

// 直接关闭浏览器的时候清除token
// window.onbeforeunload = (e) => {
//   removeToken()
//   removeRefreshToken()
// }
// window.on('beforeunload', function () { // 兼容chorme的写法， chorme 不生效 window.onbeforeunload
//   removeToken()
//   removeRefreshToken()
// })

if (window.SharedWorker) {
  const sharedWorker = new SharedWorker('/callSharedWorker.js', { name: 'parent-call-shared-worker' })
  Vue.prototype.$callWorker = sharedWorker
  addEventListener('beforeunload', () => {
    sharedWorker.port.postMessage({
      type: 'close'
    })
  })
} else {
  Vue.prototype.$callWorker = {}
}
